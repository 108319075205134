<script setup>

import MenuHeader from "../components/menu.vue"
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import keepReadingItem from "../components/home/keepReadingItem.vue"
import queryTagsItem from "../components/home/queryTagsItem.vue"
import largTagsItem from "../components/home/largTagsItem.vue"
import indicatedcontainer from "../components/home/indicatedcontainer.vue"
import Card from "../components/home/card.vue"
import footerSite from "../components/footer.vue"
import blogcontainer from "../components/home/blogcontainer.vue"
import nutrition from "../components/home/nutrition.vue"
import highlightscontainer from "../components/home/highlightscontainer.vue"
import authRegister from "@/components/register.vue"
import 'aos/dist/aos.css';
import * as aos from 'aos/dist/aos'
import { computed, onBeforeMount, onMounted, reactive, ref, watch } from "vue"
import { useStore } from "vuex"
import _ from 'lodash';
import { closeLoading, showLoadingMessage } from "@/helper/useLoading";
import { ElNotification } from "element-plus";
import { useLocalStorage } from "@vueuse/core";
import KeepReadingContainer from "@/components/home/KeepReadingContainer.vue";
import { useMobileVersion } from "@/helper/useMobileVersion";
import ResultList from "@/components/home/ResultList.vue";
import { useHomeFilterCategory } from "@/helper/useHomefilterCategory";


const store = useStore()
const isRegisterOpen = ref();
const favoritePostList = ref();
const hasNotEnteredApp = useLocalStorage('hasNotEnteredApp');
const category = ref();
const hasSearched = ref(false);
const {isMobile} = useMobileVersion();
const { redoFilter } = useHomeFilterCategory();

// reactive
const searchFind = computed(() => store.state.searchFind);
const listQuery = computed(() => store.state.listQuery);
const topQuery = reactive([
  'ansiedade',
  'depressão',
  'angústia',
  'medo',
  'relaxamento',
  'cansaço',
  'estresse'
]);
const DEFAULT_SEARCH_PLACEHOLDER = 'Digite seus tópicos de interesse';
aos.init({
  disable: false,
  startEvent: 'DOMContentLoaded',
  initClassName: 'aos-init',
  animatedClassName: 'aos-animate',
  useClassNames: false,
  disableMutationObserver: false,
  debounceDelay: 50,
  throttleDelay: 99,
  offset: 120,
  delay: 0,
  duration: 300,
  easing: 'ease',
  once: false,
  mirror: false,
  anchorPlacement: 'top-bottom',
});
let largTagsList = ref((JSON.parse(localStorage.getItem('largTagsList')) || []))
let typeSearch = ref('')

const placeHolderSearch = ref(DEFAULT_SEARCH_PLACEHOLDER);

// actions
function handleFocusInSearch() {
  placeHolderSearch.value = '';
}
function handleFocusOutSearch() {
  if (typeSearch.value == null || typeSearch.value === '') {
    placeHolderSearch.value = DEFAULT_SEARCH_PLACEHOLDER;
  }
}
function handleCLickIndicatedToYou(){
  // scroll to section of indicated posts
  const indicatedPosts = document.getElementById('indicatedPosts');
  indicatedPosts.scrollIntoView({ behavior: 'smooth' });

}

const handleQuerySearch = (item, defaultQuery) => {
  showLoadingMessage('Buscando posts');
  if (_.isEmpty(item) && _.isEmpty(defaultQuery)) {
    return;
  }
  if (defaultQuery != '') item = defaultQuery
  if (typeSearch.value == '' || typeSearch.value != item) typeSearch.value = item
  if (item != '') {
    store.dispatch('SEARCH_POST_LIST_BY_TOPIC', item);
  }
  addLastQuery(item)
  hasSearched.value = true;
  typeSearch.value = '';
  setTimeout(() => {
    closeLoading();
  }, 1000);
}

const addLastQuery = (item) => {

  if (largTagsList.value.length >= 5) {
    // remove first element of largTagsList
    largTagsList.value.shift()
  }
  const exist = largTagsList.value.filter(element => element.description === item)
  if (exist.length == 0) {
    largTagsList.value.push({
      description: item
    })
  }
}
function deleteQuery(value) {
  largTagsList.value = largTagsList.value.filter(element => element.description !== value.toString())
}

// watchers
watch(largTagsList, newValue => {
  localStorage.setItem('largTagsList', JSON.stringify(newValue))
}, { deep: true })
watch(() => listQuery.value, (newVal) => {
  if (newVal.length > 0) {
    setTimeout(() => {
      closeLoading();
      document.getElementById('resultQuery').scrollIntoView();
    }, 1000);
  } else {
    ElNotification({
      title: 'Ops!',
      message: 'Nenhum resultado encontrado',
      type: 'warning',
      duration: 2000
    });
  }
})
const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

let value = JSON.parse(params.token)

if (value) {
  store.dispatch('ADD_EXTERNAL_TOKEN', value)
}

const user = computed(() => store.getters.getUser);

// life-cycles
onBeforeMount(async () => {
  // parse hasNotEnteredApp.value  to boolean
  hasNotEnteredApp.value = JSON.parse(hasNotEnteredApp.value)

  // present message for logon
  showLoadingMessage(hasNotEnteredApp.value ? 'Bem vindo... carregando': 'Carregando...');

  document.body.className = 'home';

  // get list of personal posts for the user
  await store.dispatch('GET_FAVORISTE_LIST', category.value);

  // load config from state
  isRegisterOpen.value = store.state.isRegisterOpen;
  favoritePostList.value = store.state.favoritePostList.reverse();
  category.value = store.state.category;
  hasNotEnteredApp.value = false;
});

onMounted(() => {
  redoFilter();
});
</script>
    
<template>
  <div id="home" style="font-family: Raleway,sans-serif;">
    <header :style="{'height': store.state.postToContinue.length === 0 ? '600px' : '868px'}">
      <div class="header-shadow" :style="{'height': store.state.postToContinue.length === 0 ? '600px' : '868px'}">
        <div class="container">
          <menu-header></menu-header>
          <div class="content-header">
            <div class="query-section">
              <h4 >{{user != null && user.nickname != null ? `Olá, ${user.nickname}!` : ''}}</h4>
              <div class="inputArea">
                <input type="text" v-model="typeSearch" @focusin="handleFocusInSearch" @focusout="handleFocusOutSearch"
                  @keyup.enter="handleQuerySearch(typeSearch, '')" :placeholder="placeHolderSearch">
                <a href="" @click.prevent="handleQuerySearch(typeSearch, '')">
                  <font-awesome-icon :icon="['fa', 'search']" />
                </a>
              </div>
              <div class="queryTags">
                <span class="margin-span" v-if="hasSearched && listQuery.length == 0">
                  Não encontramos resultados para o termos de busca <b>”{{searchFind.name}}”</b> mas temos alguns resultados <a href="#" class="link" @click.prevent="handleCLickIndicatedToYou">Indicados para você</a></span>
                <div class="sugestion-list">
                  <span>Faça suas buscas por temas como: </span>
                  <div class="suggestions">
                    <query-tags-item v-for="item in topQuery" :key="item" :description="item"
                      @click="handleQuerySearch(item, '')"></query-tags-item>
                  </div>
                </div>
              </div>
              <div class="largTags">
                <larg-tags-item v-for="(item, key) in largTagsList" :key="key" :description="item.description"
                  @search-query-send="handleQuerySearch(item.description, '')" :position="key"
                  @delete-query="deleteQuery">
                </larg-tags-item>
              </div>
            </div>
            <!-- Continue de onde parou -->
            <KeepReadingContainer @finish-loading="closeLoading()" />
          </div>
  
  
        </div>
  
      </div>
    </header>
    <section id="resultQuery" class="container queryList">
  
      <highlightscontainer v-if="!hasSearched" />
  
      <div class="resultQuery" v-if="!_.isEmpty(listQuery)">
        <h2 class="">Resultado da pesquisa </h2>
        <div class="keep-reading">
          <div class="keep-reading-container " style="justify-content: center;">
            <keep-reading-item v-for="item in listQuery" style="color: black" :key="item.postId" :itemShow="item">
            </keep-reading-item>
          </div>
        </div>
      </div>
    </section>

    <ResultList  />
  
    <indicatedcontainer />
  
    <div class="container auto-overflow" v-if="!_.isEmpty(favoritePostList)">
  
      <div data-aos="fade-down" class="favorites">
  
        <h2>Seus favoritos</h2>
  
        <div class="favoritePostList">
          <Splide
            :options="{ rewind: true, autoWidth :true,  perPage: isMobile ? 1 : 3, rewindByDrag: true, padding:  '25px', pagination: false } "
            aria-label="My Favorite Images">
            <SplideSlide class="favorite-list" v-for="item in favoritePostList" :key="item.postId">
              <card :itemShow="item" />
            </SplideSlide>
          </Splide>
  
        </div>
        <!--aqui tem que ter um carousel no mobile-->
      </div>
    </div>
  
    <nutrition />
  
    <blogcontainer blog-link-style="bottom: 0 !important;position: absolute !important;" />
    
    <footer-site />
  
    <authRegister key="register" v-if="isRegisterOpen" />
  </div>

</template>

<style lang="scss">
#home{
  min-height: 4000px;
}
div>div>div.menu>nav>ul {
  align-items: flex-start;
}

nav.ulMenu.hideMenu {
  margin-top: -8px;
}
.link{
  color: white;
  text-decoration: underline;

}
//mobile width
@media (max-width: 600px) {
  .menu{
    display: flex;
    align-items: center;
    .logo{
      margin-top: 0px !important;
    }
  }
  .ulMenu.hideMenu.show-menu {
    margin-top: 80px;
    background: #215c61;
    opacity: 0.9;
    margin-top: -8px;

    .userMobile a {
      img {
        margin-right: 20px;
      }
    }
  }

  header {
    height: auto;
  }

  .content-header {
    overflow-y: hidden;
    padding-inline: 10px;

    h4 {
      margin: 20px;
      margin-bottom: 40px;
      font-size: 20px;
    }

    // input text
    .inputArea {
      input {
        font-size: 18px;
        padding: 15px;
      }

      //placeholder text same size
      ::placeholder {
        font-size: 14px;
      }

      a {
        right: 35px !important;
        font-size: 20px !important;
        margin-top: 24px !important;
      }
    }

    // suggestions
    .sugestion-list {
      max-width: 420px;

      .suggestions {
        //center the contents
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 20px;
      }
    }
  }

  .keep-reading {
    margin-top: 40px;

    h3 {
      font-size: 20px;
      margin: 20px;
      margin-bottom: 0px;
    }

    .splide {
      height: 270px;

      .splide__list {
        height: 270px;

        .splide__slide {
          height: 270px;
          margin: 0;
          padding: 0;

          .keep-reading-item {
            width: 100%;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }

  // indicated header
  div.container.indicated-info {
    margin-top: 50px;

    h2 {
      font-size: 20px;
      margin: 20px;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  // indicated content
  div.indicated {
    padding-top: 38px;
    height: 897px;

    .container {
      padding-left: 15px;
      padding-right: 15px;

      .indicated-body,
      .indicated-body-shadow {
        height: 250px;
        width: 100%;
        padding-left: 0px;

        h3 {
          font-size: 14px;
          line-height: 19px;
          padding-left: 15px;
        }
      }

      .podcast {
        height: auto;

        .podcast-info {
          margin-bottom: 10px
        }

        .podcast-img {
          height: 100%;
        }
      }
    }
  }

  // favorites
  .favorites {
    margin-top: 50px;

    h2 {
      font-size: 20px;
      margin: 20px;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .favoritePostList {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .splide.is-initialized.splide--slide.splide--ltr.splide--draggable.is-active {
        .splide__track.splide__track--slide.splide__track--ltr {
          padding-right: 15px !important;
        }
      }
    }
  }

  // nutrition
  .nutrition {
    margin-top: 50px;
    padding: 15px;

    div.item.aos-init.aos-animate>div.item-img {
      img {
        height: 175px;
        margin-top: 45px;
        margin-left: 12px;
      }
    }

    .item-description {
      margin-right: 15px;
    }
  }

  // image for novos conteúdos
  #app>div:nth-child(4)>div>div:nth-child(2)>div>div:nth-child(2)>div.item-img>img {
    margin-left: -20px;
    margin-top: 30px;
  }

  // blog


  .blog .blog-list {
    max-width: 1250px;
    width: 100%;

    // margin: 0 auto;
    .splide__slide {
      margin-right: 15px;
    }

    .blog-item {
      max-width: 400px;
      width: 100%;
      height: 208px;
      margin-right: 15px;
      margin-bottom: 147px;

      &:last-child {
        color: #181C32;
      }

      p.blog-text {
        bottom: 20px;
        // position: absolute;
      }

      a.blog-link {
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #181C32;
        bottom: 0px;
        position: absolute;
      }
    }

    img {
      width: 400px;
      max-height: 208px;
      height: 100%;
      object-fit: cover;
    }

    p,
    a {
      padding: 10px;
      color: #272727;
      font-weight: bold;
      margin-top: 22px;
    }

    p {
      margin-bottom: 22px;
    }

    a {

      font-size: 14px;

      a {
        text-decoration: underline;
      }
    }
  }

  // footer
  .footer {
    padding: 25px;

    .container {
      padding: 0px;
      margin: 0px;
      margin-right: 25px;
      align-items: flex-start;
    }
  }

  // hidden content
  .listRsultCategory {
    width: 100%;
    margin-top: 80px;
    max-width: 390px;

    h2 {
      font-size: 20px;
      margin: 20px;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .keep-reading {
      .keep-reading-container {
        display: grid;
        // 1 column
        grid-template-columns: 2fr;
        grid-gap: 10px;
        margin-top: 0px;
        margin-bottom: 0px;
        //center content
        justify-content: center;
        margin-left: 7%;

      }
    }
  }

}
</style>
 