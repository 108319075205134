import { ComputedRef, Ref, ref, watch } from "vue";
import { useStore } from "vuex";

async function generateImageFromResponse(response: any, resolve: any) {
  const blob = await response.blob();
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = resolve
}

export async function rawImageDownload(uuid: string, width: number, height: number) {
  const urlBase = process.env.VUE_APP_URL_API_BASE
  const response = await fetch(`${urlBase}General/downloadfile/${uuid}?w=${width}&h=${height}`)
  const blob = await response.blob();
  return URL.createObjectURL(blob);
}

export async function downloadLowQualityImage(uuid: string) {
  const urlBase = process.env.VUE_APP_URL_API_BASE
  // fetch the low quality image blob
  const response = await fetch(`${urlBase}General/downloadfile/${uuid}?w=10&h=10`)
  generateImageFromResponse(response, (reader) => {
    return reader.currentTarget.result;
  })
}

export function useDownloadImage(uuid: Ref<unknown> | ComputedRef<unknown>, width? : number, height? : number) {
  const store = useStore();
  if(!width) width = 325;
  if(!height) height = 325;
  const image = ref('');
  const imageStored = store.state.cacheImagens.find(image => image.uuid === uuid && image.url != null) ;
  
  async function doDownload(uuid: Ref<unknown>){
    if(uuid?.value == null) return;
    const urlBase = process.env.VUE_APP_URL_API_BASE
    const response = await fetch(`${urlBase}General/downloadfile/${uuid}?w=${width}&h=${height}`)
    generateImageFromResponse(response, (reader: any) => {
      const base64data = reader.currentTarget.result;
      store.dispatch('CACHE_IMAGE', { uuid, url: base64data })
      image.value = base64data;
    })
  }

  if (imageStored != null) {
    image.value = imageStored.url;
  } else {
    doDownload(uuid);
  }
  watch(uuid, doDownload);
  return {image};
}