<script setup>  

    import {defineProps, ref, defineEmits} from "vue"

    const emit = defineEmits(['deleteQuery', 'searchQuerySend'])

    const props = defineProps(["description", "position"])

    const search = ()=>{
        emit('searchQuerySend')
    }

    const removeTag = (value)=>{
        emit('deleteQuery', value)
    }

</script>

<template>
    <div class="larg-tags-item">
        <a @click.prevent="search" href="" class="">
            <span>
                {{description.length > 50 ? description.substring(0, 50) + '...' : description}}
            </span>    
        </a>    
        <a href="" @click.prevent="removeTag(description)" class="close-tag">
            <img src="../../assets/img/vetor/Vector(1).png" alt="">
        </a>    
    </div>        
</template>

