import { useStore } from "vuex"

async function change(postId: number): Promise<string>{
    const store = useStore();
    try{
        const response = await store.dispatch('GET_POST', postId);
      
        const lengthVideo =  response.videoPost.length
    
        const lengthAudio =  response.audioPost.length
    
        let pathRoute = '/frame/'+postId
    
        if(lengthAudio == 0 && lengthVideo > 0){
            pathRoute = '/detailVideo/'+postId
        }
    
        if(lengthAudio > 0 && lengthVideo == 0){
            pathRoute = '/detail/'+postId
        }
    
        if(lengthAudio > 0 && lengthVideo > 0){
            pathRoute = '/detailVideo/'+postId
        
            if(response.videoPost[0].urlVideo == ''){
                pathRoute = '/detail/'+postId
            }
        }
        
        return pathRoute
    }
    catch(err: any){
        console.log(err);
    }
    return '';

}
export default change